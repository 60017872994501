import React from 'react'
import { BsTwitterX, BsYoutube, BsLinkedin } from 'react-icons/bs'

const SocialMedia = () => {
  return (
    <div className='app__social'>
      <a href='https://www.linkedin.com/in/timothy-fischer-skyfish/' target='_blank' rel='noopener noreferrer'>
        <div>
          <BsLinkedin />
        </div>
      </a>
      <a href='https://www.youtube.com/@SkyFishLLC' target='_blank' rel='noopener noreferrer'>
        <div>
          <BsYoutube />
        </div>
      </a>
      <a href='https://twitter.com/SkyFishLLC' target='_blank' rel='noopener noreferrer'>
        <div>
          <BsTwitterX />
        </div>
      </a>
    </div>
  )
}

export default SocialMedia