import { createRoot } from 'react-dom/client';

import './index.css';
import App from './App';

const root = document.getElementById('root');
const reactRoot = createRoot(root);
reactRoot.render(<App />);

const toggleDarkMode = () => {
  document.documentElement.classList.toggle('dark-mode');
};

reactRoot.render(<App toggleDarkMode={toggleDarkMode} />);