import React, { useState } from 'react';

import { About, Footer, Header, Skills, Testimonial, Work } from './container';
import { Navbar } from './components';
import './App.scss';

const App = ({toggleDarkMode}) => {
  const [isDarkMode, setIsDarkMode] = useState(true);

  // Function to toggle dark mode
  const handleToggleDarkMode = () => {
    setIsDarkMode(!isDarkMode);
    toggleDarkMode(); // Call the toggleDarkMode function passed from index.js
  };

  return(
    <div className={`app ${isDarkMode ? 'dark-mode' : ''}`}>
      <Navbar isDarkMode={isDarkMode} toggleDarkMode={handleToggleDarkMode} />
      <Header isDarkMode={isDarkMode} />
      <About />
      <Work />
      <Skills />
      <Testimonial />
      <Footer />
    </div>
  );
};

export default App;